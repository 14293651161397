
import {
    defineComponent, ref, Ref
} from 'vue';
import Notice from '@/util/notice.base';
import HttpRequest from '@/util/axios.config';
import { userForSuperApi } from '@/api';

export default defineComponent({
    setup(props, { emit }) {
        const form = ref<any>();
        const fileEle = ref<any>();
        const downloadEle = ref<any>();
        const fileName = ref('');
        const formData: Ref<{
            Account: string;
        }> = ref({
            Account: ''
        });

        const rules = {
            Account: [{ required: true, message: WordList.ProperAllTextKitAccountTips, trigger: 'blur' }]
        };

        const importClick = () => {
            fileEle.value.click();
        };

        const choose = () => {
            fileName.value = fileEle.value.files[0].name;
        };

        const download = () => {
            downloadEle.value.click();
        };

        const submit = () => {
            form.value.validate((valid: boolean) => {
                if (valid) {
                    if (fileEle.value.value === '') {
                        Notice.messageBox('alert', WordList.ProperAllTextKitDevicesTips, 'Info', 'info')(() => 1);
                        return false;
                    }

                    const params = new FormData();
                    params.append('Account', formData.value.Account);
                    params.append('Device', fileEle.value.files[0]);
                    userForSuperApi.importKit(params, () => {
                        emit('close');
                    }, () => {
                        fileEle.value.value = '';
                        fileName.value = '';
                    });
                }

                return true;
            });
        };

        return {
            form,
            formData,
            rules,
            fileEle,
            fileName,
            downloadEle,
            importClick,
            choose,
            submit,
            download
        };
    }
});
